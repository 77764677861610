<script lang="ts">
	import Button from "./Button.svelte";
	import { getGlobalSettings } from "../getGlobalSettings";
	import { getContext } from "svelte";
	import { pageSlugContextKey } from "../contexts/pageSlugContextKey";
	import Link from "./Link.svelte";
	import { localeShortHandMap } from "../../../core/schema/localeShortHandMap.js";
	import type { GlobalTemplateProps } from "../GlobalTemplateProps.js";
	import { tenants } from "../../../core/config/tenants.js";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import { getTenant } from "../contexts/tenantContextKey.js";

	const { locale } = getTenant();
	const { WEB_URL_OR_LOCAL } = getGlobalSettings();
	const pageSlug = getContext<GlobalTemplateProps["pageSlug"]>(pageSlugContextKey);
	export let scrolled = false;

	export let menuTextColor = "text-text";

	let isLanguageListOpen = false;

	function toggleLanguageList(): void {
		isLanguageListOpen = !isLanguageListOpen;
	}
</script>

{#if Object.values(tenants).length > 1}
	<div class="group relative">
		<Button
			variant="transparent"
			class="group/item {scrolled
				? 'text-text'
				: menuTextColor} lg:text-2md group-hover/item:lg:!text-primary !gap-0.5 text-xs font-medium uppercase tracking-[0.125rem] transition-all duration-500 ease-in-out group-has-[.has-submenu:hover]/header:text-black lg:rounded-lg lg:px-4 lg:py-2"
			on:click={toggleLanguageList}
		>
			{nbspify(localeShortHandMap[locale])}
			<span class="bg-primary hidden h-0.5 max-w-0 transition-all duration-500 group-hover/item:max-w-full lg:block"
			></span>
		</Button>
		<div
			class="absolute left-1/2 top-[calc(100%+.25rem)] w-[calc(100%+1rem)] -translate-x-1/2 justify-center rounded-lg bg-white shadow-sm transition-opacity duration-500 ease-in-out lg:flex lg:after:absolute lg:after:-top-1 lg:after:left-0 lg:after:right-0 lg:after:h-1 lg:after:w-full lg:after:content-[''] lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 {isLanguageListOpen
				? 'pointer-events-auto opacity-100 lg:pointer-events-none lg:opacity-0'
				: 'pointer-events-none opacity-0 lg:pointer-events-none lg:opacity-0'}"
		>
			{#each Object.values(tenants) as tenant}
				{#if tenant.locale !== locale}
					<Link
						class="lg:text-md hover:text-primary !block w-full rounded-lg p-2 text-center text-xs font-semibold uppercase tracking-[0.125rem] transition-colors duration-500 ease-in-out lg:font-medium"
						asButton
						variant="transparent"
						href="{WEB_URL_OR_LOCAL}/{pageSlug[tenant.locale] ?? ''}"
					>
						{nbspify(localeShortHandMap[tenant.locale])}
					</Link>
				{/if}
			{/each}
		</div>
	</div>
{/if}
