import type { MenuItem, SubmenuItem } from "../../../core/schema/MenuItem.js";

export function isMenuActive(menuItem: MenuItem | SubmenuItem, pageSlug: string): boolean {
	if (pageSlug === menuItem.path) {
		return true;
	}

	if ("submenus" in menuItem) {
		return menuItem.submenus.some((menuItems) => {
			if (Array.isArray(menuItems)) {
				return menuItems.some((menuItem) => isMenuActive(menuItem, pageSlug));
			}
			return false;
		});
	}

	return false;
}
