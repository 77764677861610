<script lang="ts">
	import { onMount } from "svelte";
	import { gsap } from "gsap";
	import type { MenuItem } from "../../../../core/schema/MenuItem";
	import Icon from "../Icon.svelte";
	import close from "../../../../core/assets/icons/close.svg?raw";
	import Menu from "./Menu.svelte";
	import logo from "../../../../core/assets/branologo.svg?raw";

	export let isOpen = false;
	export let menu: MenuItem[];

	let navElement = null as HTMLElement | null;

	function toggleMenuAnimation(opened: boolean): void {
		if (opened) {
			gsap.fromTo(navElement, { height: "0dvh" }, { height: "100dvh", duration: 0.25, ease: "power4.out" });
		} else {
			gsap.fromTo(navElement, { height: "100dvh" }, { height: "0vh", padding: 0, duration: 0.25, ease: "power4.out" });
		}
	}

	function onClose(): void {
		isOpen = false;
	}

	function onClick({ detail }: CustomEvent<MenuItem>): void {
		if (detail.id === "kontakt") {
			isOpen = false;
		}
	}

	// This is to fix the element clipping issue at the start of the animation
	$: {
		if (isOpen) {
			setTimeout(() => {
				navElement?.classList.add("!top-0");
			}, 300);
		}
	}

	onMount(() => {
		gsap.set(navElement, { height: isOpen ? "100dvh" : "0dvh" });
	});

	$: {
		if (navElement) {
			toggleMenuAnimation(isOpen);
		}
	}
</script>

<nav
	bind:this={navElement}
	class="bg-secondary absolute -top-[1.5rem]
		right-0 z-50 m-0 flex w-full flex-col overflow-y-auto overflow-x-hidden px-[0.8125rem] py-3 transition-all duration-500"
>
	<div class="container flex items-center justify-between pt-4">
		<a href="/" class="w-[5.5rem]">
			<Icon icon={logo} class="text-text h-[4.274375rem] w-full" />
		</a>
		<button class="mt-1" on:click={onClose}>
			<Icon icon={close} class="h-[2.4375rem]" />
		</button>
	</div>

	<Menu {menu} on:click={onClick} />
</nav>
