<script lang="ts">
	import { getTranslate } from "../utils/getTranslate";
	import translations from "./Footer.translations.json";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import { appName } from "../../../core/schema/appName";
	import fb from "../../../core/assets/icons/fb.svg?raw";
	import linkedin from "../../../core/assets/icons/linkedin.svg?raw";
	import ig from "../../../core/assets/icons/ig.svg?raw";
	import Link from "./Link.svelte";
	import { getContext } from "svelte";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";
	import { getTenant } from "../contexts/tenantContextKey.js";
	import { aboutUsPathMap } from "../../../core/schema/paths/aboutUsPathMap.js";
	import { productGroupsContextKey } from "../contexts/productGroupsContextKey.js";
	import type { ProductGroupWithTextifiedContent } from "../../../core/schema/ProductGroup.js";
	import { getProductGroupPath } from "../../../core/schema/paths/getProductGroupPath.js";
	import { Category } from "../../../core/schema/Category.js";
	import { careerPathMap } from "../../../core/schema/paths/careerPathMap.js";
	import { getPathInLocale } from "../../../core/schema/paths/getPathInLocale.js";
	import { contactPathMap } from "../../../core/schema/paths/contactPathMap.js";
	import logoCrea from "../../../core/assets/logo-creavision.svg?raw";
	import logoFastest from "../../../core/assets/logo-fastest.svg?raw";
	import Icon from "./Icon.svelte";

	export let image: string | undefined;
	export let headline: string | undefined;
	export let subHeadline: string | undefined;
	export let buttonText: string | undefined;
	export let largeImage = false;
	export let imageClass = "";
	let footerClass = "";
	export { footerClass as class };

	const { locale } = getTenant();

	const links: { name: keyof typeof translations; link: string }[] = [
		{ name: "aboutUs", link: `/${getPathInLocale(locale, aboutUsPathMap)}` },
		{ name: "eshop", link: "https://branomarket.brano.eu" },
		{ name: "career", link: `/${getPathInLocale(locale, careerPathMap)}` },
		{ name: "contacts", link: `/${getPathInLocale(locale, contactPathMap)}` },
	];

	const translate = getTranslate(translations);
	const productGroups = getContext<ProductGroupWithTextifiedContent[]>(productGroupsContextKey);
</script>

<footer class="{footerClass} lg:w-full 2xl:mx-auto {!largeImage && 'pt-36 lg:pt-[11.81rem]'}">
	{#if image}
		<div class="relative z-10 max-lg:h-[34rem]">
			<div class="container absolute inset-0 -top-16 left-0 -z-10 sm:-top-10 lg:top-0">
				<div class="flex py-4 lg:h-full lg:flex-row">
					<div use:flyInOnScroll>
						{#if headline}
							<span class="lg:text-2lg text-lg font-normal leading-[1.13]">{headline}</span>
						{/if}
						{#if subHeadline}
							<p class="text-xl font-semibold leading-[1] lg:text-4xl">{subHeadline}</p>
						{/if}
					</div>
				</div>
			</div>
			<StaticPicture
				{image}
				class="z-10 h-full w-full object-cover"
				imgClass="{largeImage
					? 'lg:h-[71.125rem] h-[56.25rem]'
					: ''} lg:h-[46.5rem] h-96 w-full lg:w-full object-cover {imageClass}"
				width={1920}
				height={1080}
				alt={headline ?? appName}
			/>
			{#if buttonText}
				<div class="container absolute bottom-20 left-1/2 flex w-full -translate-x-1/2 justify-end lg:top-4">
					<Link asButton animated href="/{getPathInLocale(locale, aboutUsPathMap)}" containerClass="lg:!w-fit">
						<span class="max-w-[15rem]">
							{buttonText}
						</span>
					</Link>
				</div>
			{/if}
		</div>
	{/if}
	<div
		class="bg-secondary"
		class:lg:-mb-[10rem]={image}
		class:lg:-mb-[30rem]={!headline && !subHeadline && !buttonText}
	>
		<div
			class="flex flex-col gap-5 lg:relative lg:gap-20"
			class:-top-[10rem]={image}
			class:-top-[30rem]={!headline && !subHeadline && !buttonText}
		>
			<div
				class="bg-tertiary/70 container z-20 flex w-full flex-col py-[5rem] backdrop-blur lg:gap-[4.75rem] lg:px-[3.75rem]"
			>
				<div class="flex flex-col lg:flex-row lg:justify-between">
					<div>
						<span class="text-[2.75rem] font-semibold leading-[3.125rem] lg:text-[4.625rem] lg:leading-[5.25rem]"
							>{translate("title")}</span
						>
						<span class="mt-[4rem] flex flex-col">
							<a href="/" class="text-lg lg:text-[2.375rem]">BRANO GROUP a.s.,</a>
							<a href="https://maps.app.goo.gl/LcbA6Q5u4FqycSB18" target="_blank" class="text-lg lg:text-[2.375rem]">
								747 41 Hradec nad Moravicí, Czech&nbsp;Republic
							</a>
							<span class="mt-[2.5rem] flex flex-col">
								<a
									href="mailto:info@brano.cz"
									class="text-[1.75rem] font-bold lg:text-[2.375rem] lg:font-normal lg:leading-[3.125rem]"
									>info@brano.cz</a
								>
								<a
									href="tel:+420553632211"
									class="text-[1.75rem] font-bold lg:text-[2.375rem] lg:font-normal lg:leading-[3.125rem]"
									>+420 553 632 211</a
								>
							</span>
						</span>
					</div>
					<div
						class="my-[3.69rem] flex justify-between px-[1.6875rem] lg:my-0 lg:mb-[2.47rem] lg:mr-[4.68rem] lg:h-auto lg:items-end lg:justify-normal lg:gap-[7.08rem] lg:px-0 xl:mr-[13.68rem]"
					>
						<Link
							href="https://www.facebook.com/groupbrano/?paipv=0&eav=AfYRcYA1-GsqsNVNIdevf2-kuPWkwW8L9ddB5GJmg1eHHY0bh6_Izl98Qu0wAeSR8Bs&_rdr"
							variant="transparent"
							icon={fb}
							iconClass="h-[2.786875rem] text-[#AEAEB2]"
						/>
						<Link
							href="https://cz.linkedin.com/company/brano-a.s."
							variant="transparent"
							icon={linkedin}
							iconClass="h-[2.786875rem] text-[#AEAEB2]"
						/>
						<Link
							href="https://www.instagram.com/brano.group"
							variant="transparent"
							icon={ig}
							iconClass="h-[2.786875rem] text-[#AEAEB2]"
						/>
					</div>
				</div>
				<div
					class="flex flex-col gap-y-16 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-0"
					style:grid-template-rows="repeat({Math.max(
						links.length,
						productGroups
							.filter(({ category }) => category === Category.UtilityVehicles)
							.flatMap(({ products }) => products).length,
						productGroups.filter(({ category }) =>
							[Category.PassengerVehicles, Category.UtilityVehicles].includes(category),
						).length,
					)}, auto)"
				>
					<div class="row-span-full grid grid-rows-subgrid gap-y-4 lg:gap-y-8">
						{#each productGroups.filter( ({ category }) => [Category.PassengerVehicles, Category.UtilityVehicles].includes(category), ) as productGroup}
							<a href={`/${getProductGroupPath(productGroup)}`} class="text-[1.4375rem] font-light">
								{productGroup.name}
							</a>
						{/each}
					</div>
					<div class="row-span-full grid grid-rows-subgrid gap-y-4 lg:gap-y-8">
						{#each productGroups.filter(({ category }) => category === Category.UtilityVehicles) as productGroup}
							{#each productGroup.products as product}
								<a
									target={product.externalUrl ? "_blank" : "_self"}
									href={product.externalUrl ?? `/${getProductGroupPath(productGroup)}`}
									class="text-[1.4375rem] font-light"
								>
									{product.name}
								</a>
							{/each}
						{/each}
					</div>
					<div class="row-span-full grid grid-rows-subgrid gap-y-4 lg:gap-y-8">
						{#each links as link}
							<a
								class="text-[1.4375rem] font-semibold lg:font-light"
								target={link.link.startsWith("http") ? "_blank" : "_self"}
								href={link.link}
							>
								{translate(link.name)}
							</a>
						{/each}
					</div>
				</div>
			</div>
			<div class="container flex flex-col gap-[4rem] py-8 lg:px-[3.75rem]">
				<div class="grid gap-4 text-[#808080] lg:grid-cols-2">
					<a href="#todo" class="text-[1.125rem] font-light lg:text-[1.4375rem]">
						{translate("gdpr")}
					</a>
					<a href="#todo" class="text-[1.125rem] font-light lg:text-[1.4375rem]">
						{translate("law")}
					</a>
					<a href="#todo" class="text-[1.125rem] font-light lg:text-[1.4375rem]">
						{translate("terms")}
					</a>
					<a href="#todo" class="text-[1.125rem] font-light lg:text-[1.4375rem]">
						{translate("grant")}
					</a>

					<a href="#todo" class="text-[1.125rem] font-light lg:text-[1.4375rem]">
						{translate("cookies")}
					</a>
				</div>
				<span class="text-[1.125rem] font-light text-[#808080] lg:text-[1.4375rem]">
					{translate("copy")}
				</span>
			</div>
		</div>
	</div>
	<div class="bg-secondary">
		<div class="container h-44 lg:h-32">
			<div
				class="md:text-2md flex flex-col justify-center gap-6 font-light text-[#808080] lg:flex-row lg:items-center lg:gap-12"
			>
				<div class="flex items-center gap-6 lg:justify-center">
					<div>Created & Designed by</div>
					<Link href="https://www.creavision.cz/" class="g-12 lg:h-20">
						<Icon icon={logoCrea} class="!aspect-auto w-20 md:w-44" />
					</Link>
				</div>
				<div class="hidden lg:flex">/</div>
				<div class=" flex items-center gap-6 lg:justify-center">
					<div>Developed by</div>
					<Link href="https://www.fastest.cz" class="h-12 lg:h-20">
						<Icon icon={logoFastest} class="!aspect-auto w-20 md:w-44" />
					</Link>
				</div>
			</div>
		</div>
	</div>
</footer>
