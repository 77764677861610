import { getContext } from "svelte";
import type { Tenant } from "../../../core/schema/Tenant.js";

/**
 * Automatically set key in context by renderer in cli.mts and createHtmlTemplate.ts
 * Contains tenant for current page
 */
export const tenantContextKey = "tenant";

export function getTenant(): Tenant {
	return getContext<Tenant>(tenantContextKey);
}
