import type { ButtonProps, ButtonVariant } from "./ButtonProps.js";

export function generateButtonClasses({ variant, disabled, reversed }: ButtonProps): string {
	const btnVariants: Record<ButtonVariant, string> = {
		primary: "bg-primary hover:bg-primary-100 text-white",
		secondary: "bg-tertiary hover:bg-tertiary-300 text-black",
		tertiary: "bg-white hover:bg-primary text-black hover:text-white",
		transparent: "group text-black flex gap-2 items-center",
		primaryTransparent: "group text-primary flex gap-4 items-center",
		custom: "",
	};
	const variantClass = btnVariants[variant ?? "primary"];

	const disabledClass = disabled && "opacity-50 pointer-events-none";

	if (variant !== "transparent" && variant !== "primaryTransparent") {
		return [
			`text-2md flex ${reversed && "flex-row-reverse"} h-[9.25rem] cursor-pointer w-full max-w-[27.5rem] sm:w-[27.5rem] max-w-[27.5rem] items-end justify-between px-6 py-[1.375rem] opacity-[94%] transition-all duration-300 hover:rounded-bl-[2rem] hover:rounded-tr-[2rem] hover:opacity-100`,
			variantClass,
			disabledClass,
		]
			.filter(Boolean)
			.join(" ");
	}

	return [variantClass, disabledClass].filter(Boolean).join(" ");
}
