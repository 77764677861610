import { Locale, type LocalizedString } from "./Locale.js";

export enum Category {
	PassengerVehicles = "PassengerVehicles",
	UtilityVehicles = "UtilityVehicles",
	OtherFieldsOfActivity = "OtherFieldsOfActivity",
}

export const categoryNameMap: Record<Category, LocalizedString> = {
	[Category.PassengerVehicles]: {
		[Locale.cs]: "Osobní vozy",
		[Locale.en]: "Passenger vehicles",
	},
	[Category.UtilityVehicles]: {
		[Locale.cs]: "Užitkové vozy",
		[Locale.en]: "Utility vehicles",
	},
	[Category.OtherFieldsOfActivity]: {
		[Locale.cs]: "Další oblasti působení",
		[Locale.en]: "Other fields of activity",
	},
};

export const categorySlugMap: Record<Category, LocalizedString> = {
	[Category.PassengerVehicles]: {
		[Locale.cs]: "osobni-vozy",
		[Locale.en]: "passenger-vehicles",
	},
	[Category.UtilityVehicles]: {
		[Locale.cs]: "uzitkove-vozy",
		[Locale.en]: "utility-vehicles",
	},
	[Category.OtherFieldsOfActivity]: {
		[Locale.cs]: "dalsi-oblasti-pusobeni",
		[Locale.en]: "other-fields-of-activity",
	},
};
