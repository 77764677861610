import type { ProductGroup } from "../ProductGroup.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getProductGroupPath(productGroup: Pick<ProductGroup, "locale" | "urlSlug">, inLocale = true): string {
	const path = productGroup.urlSlug;
	if (inLocale) {
		return getPathInLocale(productGroup.locale, path);
	} else {
		return path;
	}
}
