<script lang="ts">
	import type { ButtonProps } from "./ButtonProps.js";
	import { generateButtonClasses } from "./ButtonClasses.js";
	import Icon from "./Icon.svelte";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";
	import arrowLeft from "../../../core/assets/icons/arrow-left.svg?raw";
	import arrowRight from "../../../core/assets/icons/arrowRight.svg?raw";

	type $$Props = ButtonProps;

	$: ({
		active = false,
		icon,
		iconFront = false,
		iconClass,
		disabled = false,
		variant = "primary",
		reversed,
		noDefaultIcon,
		animated,
		...restProps
	} = $$restProps as $$Props);

	$: type = restProps.type ?? "button";
	$: btnClasses = [restProps.class, generateButtonClasses({ variant, disabled, active })].filter(Boolean).join(" ");

	let buttonElement: HTMLButtonElement | undefined;
	$: if (animated && buttonElement) {
		flyInOnScroll(buttonElement);
	}
</script>

<button bind:this={buttonElement} {...restProps} class={btnClasses} {type} {disabled} on:click on:mousedown>
	{#if icon && iconFront}
		<Icon {icon} class="max-w-9 {disabled ? 'text-secondary-300' : ''} {iconClass}" />
	{/if}
	{#if !noDefaultIcon && reversed && variant !== "transparent"}
		<Icon icon={arrowLeft} class="ml-2 h-[1.438rem] w-[1.125rem]" />
	{/if}
	<slot />
	{#if icon && !iconFront}
		<Icon
			{icon}
			class="max-w-9 {disabled ? 'group-hover:text-secondary-300 transition-colors duration-500' : ''} {iconClass}"
		/>
	{/if}
	{#if !noDefaultIcon && !reversed && variant !== "transparent"}
		<Icon icon={arrowRight} class="ml-2 h-[1.438rem] w-[1.125rem]" />
	{/if}
</button>
