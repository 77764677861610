import type { UploadedFile } from "./UploadedFile.js";

export enum SpecialMenuItem {
	Socials = "Socials",
}

export interface SubmenuItem {
	id: string;
	name: string;
	path: string;
	image?: string | UploadedFile;
	target?: "_self" | "_blank";
	showOnMobile?: boolean;
}

export interface MenuItem {
	id: string;
	name: string;
	path?: string;
	submenus: (SpecialMenuItem | SubmenuItem[])[];
	target?: "_self" | "_blank";
}
