import { gsap } from "gsap";
import type { ActionReturn } from "svelte/action";

export function flyInOnScroll(
	node: HTMLElement,
	{
		disable,
		threshold = 0.25,
		y = 50,
		duration = 1,
		delay = 0,
		ease = "power2.out",
	}: {
		disable?: boolean;
		threshold?: number;
		y?: number;
		duration?: number;
		delay?: number;
		ease?: string;
	} = {},
): ActionReturn {
	if (disable) {
		return {};
	}

	let hasAnimated = false;

	gsap.set(node, { opacity: 0, y }); // Set starting position

	const animateIn = (element: HTMLElement) => {
		gsap.fromTo(
			element,
			{ opacity: 0, y },
			{
				opacity: 1,
				y: 0,
				duration,
				delay,
				ease,
			},
		);
	};

	const observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && !hasAnimated) {
					animateIn(node);
					hasAnimated = true;
				}
			});
		},
		{ threshold },
	);

	observer.observe(node);

	return {
		destroy() {
			observer.disconnect();
		},
	};
}
