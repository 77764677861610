export const globalSettingsContextKey = "globalSettings";

export interface GlobalSettings {
	VERSION: string;
	ENV: string;
	ENV_DOMAIN: string;
	ROBOTS: "index" | "noindex";
	STORAGE_URL: string;
	UPLOAD_URL: string;
	WEB_URL: string;
	WEB_URL_OR_LOCAL: string;
	PUBLIC_GRAPHQL_ENDPOINT: string;
	COOKIES: string;
	GOOGLE_ANALYTICS: string;
	CLARITY_TOKEN: string;
}
