<script lang="ts">
	import { getGlobalSettings } from "../getGlobalSettings.js";

	const { CLARITY_TOKEN } = getGlobalSettings();

	/* eslint-disable */
</script>

<svelte:head>
	{@html `
		<script type="text/javascript">
			(function (c, l, a, r, i, t, y) {
				c[a] =
					c[a] ||
					function () {
						(c[a].q = c[a].q || []).push(arguments);
					};
				t = l.createElement(r);
				t.async = 1;
				t.src = "https://www.clarity.ms/tag/" + i;
				y = l.getElementsByTagName(r)[0];
				y.parentNode.insertBefore(t, y);
			})(window, document, "clarity", "script", "${CLARITY_TOKEN}");
		</script>
	`}
</svelte:head>
