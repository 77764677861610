<script lang="ts">
	import Button from "./Button.svelte";
	import type { MenuItem } from "../../../core/schema/MenuItem.js";
	import Drawer from "./Drawer.svelte";
	import MobileMenu from "./Menu/MobileMenu.svelte";
	import DesktopMenu from "./Menu/DesktopMenu.svelte";
	import { showHeaderStore } from "../stores/showHeaderStore.js";
	import LanguagePicker from "./LanguagePicker.svelte";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./Header.translations.json";
	import { getPathInLocale } from "../../../core/schema/paths/getPathInLocale.js";
	import { getTenant } from "../contexts/tenantContextKey.js";
	import logo from "../../../core/assets/branologo.svg?raw";
	import Icon from "./Icon.svelte";
	import burger from "../../../core/assets/icons/menu-burger.svg?raw";
	import { hideHeaderStore } from "../stores/hideHeaderStore.js";

	export let menu: MenuItem[];
	export let menuTextColor = "text-text";
	let customClass: string;

	export { customClass as class };

	let isMenuOpen = false;

	const { locale } = getTenant();
	const translate = getTranslate(translations);

	function toggleMenu(): void {
		isMenuOpen = !isMenuOpen;
	}

	let scrollPosition = 0;
	let scrollFromLastDirectionChange = 0;
	const scrollToleranceUp = 25;
	const scrollToleranceDown = 450;
	let showHeader = true;

	function onScroll(event: UIEvent & { currentTarget: EventTarget & Window }): void {
		const { scrollY } = event.currentTarget;
		const newScrollPosition = Math.max(scrollY, 0);
		const oldDirection = scrollFromLastDirectionChange < 0 ? "up" : "down";
		const newDirection = newScrollPosition > scrollPosition ? "down" : "up";

		if (oldDirection !== newDirection) {
			scrollFromLastDirectionChange = 0;
		}
		scrollFromLastDirectionChange += newScrollPosition - scrollPosition;

		scrollPosition = newScrollPosition;

		if (
			Math.abs(scrollFromLastDirectionChange) > scrollToleranceUp &&
			(scrollFromLastDirectionChange < 0 || newScrollPosition > scrollToleranceDown)
		) {
			showHeader = scrollFromLastDirectionChange < 0;
		}

		if (Math.abs(scrollFromLastDirectionChange) > scrollToleranceDown && scrollFromLastDirectionChange > 0) {
			showHeader = scrollFromLastDirectionChange < 0;
		}
	}

	$: headerVisible = (showHeader || $showHeaderStore) && !$hideHeaderStore;
</script>

<svelte:window on:scroll={onScroll} />

{#if menu.length > 0}
	<!--Keep a bit of overhead for animation duration so that the animation has smooth ending !-->
	<Drawer bind:isOpen={isMenuOpen} animationDuration={700}>
		<MobileMenu bind:isOpen={isMenuOpen} {menu} />
	</Drawer>
{/if}

<!-- TODO - grayscale added to prevent flickering of header on page scroll in FireFox - https://stackoverflow.com/questions/30411909/css-transition-flickering-in-firefox -->
<header
	class="group/header border-b-1 fixed left-0 right-0 top-0 z-40 h-[5rem] w-full border-solid md:h-[7.75rem] {customClass} {scrollPosition >
	0
		? 'bg-secondary'
		: 'bg-transparent backdrop-blur-[1.5px]'} pb-3 grayscale-[1%] transition-all duration-500 lg:h-auto lg:py-0"
	class:border-gray-200={scrollPosition > 0}
	class:bg-header={scrollPosition <= 0 && menuTextColor !== "text-text"}
	class:border-transparent={scrollPosition <= 0}
	class:[&:not(:has(.has-submenu:hover))]:-translate-y-full={!headerVisible}
	class:translate-y-0={headerVisible}
>
	<div class="border-primary h-full w-full border-t-[0.3125rem] px-[0.8125rem] lg:px-0">
		<div
			class="flex h-full w-full items-center gap-4 max-xl:container lg:w-auto lg:justify-between lg:gap-2 lg:py-[1.0625rem] xl:m-auto xl:gap-12 xl:px-[4.25rem] 2xl:max-w-[2100px]"
		>
			<div class="z-10 flex w-full items-center justify-between lg:w-full lg:gap-[3.75rem]">
				{#if menu.length > 0}
					<DesktopMenu {menuTextColor} {menu} scrolled={scrollPosition > 0} />
				{/if}
				<a href="/{getPathInLocale(locale, '')}" class="h-full w-[5.5rem] lg:hidden xl:w-28">
					<Icon
						icon={logo}
						class="h-[3rem] w-full md:h-[4.274375rem] lg:h-auto xl:h-[4.274375rem] {scrollPosition > 0
							? 'text-text'
							: menuTextColor} transition-colors duration-500 ease-in-out"
					/>
				</a>
				<div class="flex items-center gap-10">
					<a href="/{getPathInLocale(locale, '')}" class="hidden h-full w-[5.5rem] lg:inline-block xl:w-28">
						<Icon
							icon={logo}
							class="h-[3rem] w-full group-has-[.has-submenu:hover]/header:text-black md:h-[4.274375rem] lg:h-auto xl:h-[4.274375rem] {scrollPosition >
							0
								? 'text-text'
								: menuTextColor} transition-colors duration-500 ease-in-out"
						/>
					</a>
					<LanguagePicker {menuTextColor} scrolled={scrollPosition > 0} />

					<Button
						class="lg:hidden"
						variant="transparent"
						icon={burger}
						iconClass="{scrollPosition > 0
							? 'text-text'
							: menuTextColor} h-[1.75rem] md:h-[2.4375rem] transition-colors duration-500 ease-in-out"
						on:click={toggleMenu}
						aria-label={translate("menu")}
					></Button>
				</div>
			</div>
		</div>
	</div>
</header>
