<script lang="ts">
	import type { Media } from "../../../../core/schema/Media.js";
	import { thumb } from "../../../../core/utils/thumb.js";
	import { onMount } from "svelte";
	import { sortSourceSetQuery } from "../../utils/sortSourceSetQuery.js";
	import { parseSourceSet } from "../../utils/parseSourceSet.js";
	import type { SourceSetQuery } from "../../utils/SourceSetQuery.js";

	export let width: number;
	export let height: number;
	export let imgClass = "";
	export let loading: "eager" | "lazy";
	export let image: Media;
	export let alt: string | undefined;
	export let sourcesets: Partial<Record<SourceSetQuery, [number, number] | [number, number, Media]>> = {};
	export let bucketUrl: string;
	export let naturalHeight: number | undefined = undefined;
	export let naturalWidth: number | undefined = undefined;

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, [number, number] | [number, number, Media]][];

	onMount(() => {
		window.refreshFsLightbox?.();
	});
</script>

<picture>
	{#each sortedSourcesets as [query, [width, height, sourceSetImage]]}
		<source
			srcset={`${thumb(bucketUrl, width, height, sourceSetImage ?? image)} 1x`}
			type="image/webp"
			media={parseSourceSet(query)}
		/>
	{/each}

	<img
		class={imgClass}
		{alt}
		src={thumb(bucketUrl, width, 0, image)}
		{width}
		{height}
		{loading}
		bind:naturalHeight
		bind:naturalWidth
	/>
</picture>
