<script lang="ts">
	import type { LinkProps } from "./LinkProps.js";
	import { generateLinkClasses } from "./LinkClasses.js";
	import Icon from "./Icon.svelte";
	import AriaText from "./AriaText.svelte";
	import arrowLeft from "../../../core/assets/icons/arrowLeft.svg?raw";
	import arrowRight from "../../../core/assets/icons/arrowRight.svg?raw";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";

	type $$Props = LinkProps;

	$: ({
		asButton = false,
		noUnderline = false,
		icon,
		iconFront = false,
		iconClass,
		linkClass,
		disabled = false,
		variant,
		linkVariant,
		noDefaultIcon,
		reversed,
		animated,
		containerClass,
		...restProps
	} = $$restProps as $$Props);

	$: linkClasses = [restProps.class, generateLinkClasses({ variant, linkClass, linkVariant, asButton, noUnderline })]
		.filter(Boolean)
		.join(" ");

	let divElement: HTMLDivElement | undefined;
	$: if (animated && divElement) {
		flyInOnScroll(divElement);
	}
</script>

<div class="group {containerClass} {asButton ? 'w-full' : 'w-fit'}" bind:this={divElement}>
	<a
		{...restProps}
		class="{linkClasses} group"
		href={restProps.href}
		on:click
		aria-disabled={!restProps.href || disabled}
		role={restProps.href ? "link" : "none"}
		rel={restProps.target === "_blank" ? "noopener noreferrer" : restProps.rel}
	>
		{#if icon && iconFront}
			<Icon {icon} class="max-w-9 {disabled ? 'text-secondary-300' : ''} {iconClass}" />
		{/if}
		{#if !noDefaultIcon && reversed && asButton && variant !== "transparent"}
			<Icon icon={arrowLeft} class="ml-4 h-[1.438rem] w-[1.125rem]" />
		{/if}
		<AriaText aria={restProps["aria-label"] ?? ""}>
			<slot />
		</AriaText>
		{#if icon && !iconFront}
			<Icon {icon} class="max-w-9 {disabled ? 'text-secondary-300' : ''} {iconClass}" />
		{/if}
		{#if !noDefaultIcon && !reversed && asButton && variant !== "transparent"}
			<Icon icon={arrowRight} class="ml-4 h-[1.438rem] w-[1.125rem]" />
		{/if}
	</a>
	{#if variant === "primary"}
		<span
			class="bg-primary hidden h-0.5 max-w-0 transition-all duration-500 lg:block {icon
				? 'group-hover:max-w-[80%]'
				: 'group-hover:max-w-full'}"
		></span>
	{/if}
</div>
