<script lang="ts">
	import { onMount } from "svelte";
	import { sortSourceSetQuery } from "../../utils/sortSourceSetQuery.js";
	import type { SourceSetQuery } from "../../utils/SourceSetQuery.js";
	import { parseSourceSet } from "../../utils/parseSourceSet.js";
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";

	export let width: number;
	export let height: number;
	export let imgClass = "";
	export let loading: "eager" | "lazy" = "lazy";
	export let image: string;
	export let alt: string;
	export let sourcesets: Partial<Record<SourceSetQuery, string>> = {};
	export let naturalHeight: number | undefined = undefined;
	export let naturalWidth: number | undefined = undefined;
	export let flyIn: boolean | undefined = false;

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, string][];

	onMount(() => {
		window.refreshFsLightbox?.();
	});
</script>

<picture>
	{#each sortedSourcesets as [query, image]}
		<source srcset={`${image} 1x`} type="image/webp" media={parseSourceSet(query)} />
	{/each}

	{#if flyIn}
		<img
			use:flyInOnScroll={{ delay: 0.25 }}
			class={imgClass}
			{alt}
			src={image}
			{width}
			{height}
			{loading}
			bind:naturalHeight
			bind:naturalWidth
		/>
	{:else}
		<img class={imgClass} {alt} src={image} {width} {height} {loading} bind:naturalHeight bind:naturalWidth />
	{/if}
</picture>
