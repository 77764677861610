<script lang="ts">
	import { scrollLockStore } from "../stores/scrollLockStore.js";

	export let isOpen = false;
	export let animationDuration: number;
	export let onClose: () => boolean | Promise<boolean> = () => true;

	let isShown = false;

	let drawerClass = "";
	export { drawerClass as class };

	$: {
		if (isOpen && !$scrollLockStore) {
			scrollLockStore.set(isOpen);
		}
	}
	$: {
		if (!isOpen) {
			scrollLockStore.set(isOpen);
		}
	}

	async function close(): Promise<void> {
		const shouldClose = await onClose();

		if (shouldClose) {
			isOpen = false;
		}
	}

	$: if (isOpen) {
		isShown = true;
	} else {
		setTimeout(() => {
			isShown = false;
		}, animationDuration);
	}
</script>

{#if isShown}
	<div class="fixed inset-0 z-50 flex">
		<button
			type="button"
			tabindex="-1"
			class="grid h-full w-full flex-1 cursor-default place-items-center"
			on:click|self={close}
		></button>
		<div class="relative w-full overflow-y-auto px-[.8rem] py-12 min-[450px]:p-14 {drawerClass}">
			<slot />
		</div>
	</div>
{/if}
