<script lang="ts">
	import { isMenuActive } from "../../utils/isMenuActive.js";
	import Link from "../Link.svelte";
	import { createEventDispatcher, getContext, onMount } from "svelte";
	import { type MenuItem, SpecialMenuItem } from "../../../../core/schema/MenuItem.js";
	import { pageSlugContextKey } from "../../contexts/pageSlugContextKey.js";
	import type { GlobalTemplateProps } from "../../GlobalTemplateProps.js";
	import { nbspify } from "../../../../core/utils/nbspify.js";
	import { getTenant } from "../../contexts/tenantContextKey.js";
	import { gsap } from "gsap";
	import externalLinkIcon from "../../../../core/assets/icons/external-link.svg?raw";
	import fb from "../../../../core/assets/icons/fb.svg?raw";
	import linkedin from "../../../../core/assets/icons/linkedin.svg?raw";
	import ig from "../../../../core/assets/icons/ig.svg?raw";
	import Icon from "../Icon.svelte";
	import Button from "../Button.svelte";
	import DynamicPicture from "../Picture/DynamicPicture.svelte";
	import StaticPicture from "../Picture/StaticPicture.svelte";

	export let menu: MenuItem[];
	export let scrolled = false;
	export let menuTextColor = "text-text";

	const { locale } = getTenant();
	const pageSlug = getContext<GlobalTemplateProps["pageSlug"]>(pageSlugContextKey);
	const dispatch = createEventDispatcher<{ click: MenuItem }>();

	onMount(() => {
		if (window.innerWidth < 1324) {
			gsap.from(".menu-item", {
				y: 100,
				opacity: 0,
				delay: 0.5,
				duration: 1,
				stagger: 0.075,
				ease: "power3.out",
			});
		}
	});

	const openSubmenus: Record<string, boolean> = {};
	function toggleSubmenu(menuItem: MenuItem): void {
		openSubmenus[menuItem.id] = !(openSubmenus[menuItem.id] ?? false);
	}
</script>

<div
	class="mt-[4rem] flex justify-center max-lg:container max-lg:overflow-y-auto max-lg:overflow-x-hidden lg:mt-0 lg:h-full lg:w-auto"
>
	<div
		class="flex w-full flex-col items-center gap-1 sm:gap-2 lg:flex-row lg:justify-center lg:gap-[2.25rem] lg:overflow-hidden"
	>
		{#each menu as menuItem, index}
			{@const hasSubmenu = menuItem.submenus.length > 0}
			<div
				class="menu-item {index < menu.length - 1
					? 'border-b-2'
					: ''} border-tertiary group/menu-item w-full lg:w-auto lg:border-none"
				class:has-submenu={hasSubmenu}
			>
				<div class="relative w-full lg:w-fit">
					<div class="flex w-full justify-between">
						<Link
							noUnderline
							containerClass={!hasSubmenu ? "!w-full" : ""}
							class="{menuTextColor === 'text-secondary'
								? 'lg:text-secondary'
								: `lg:${menuTextColor}`} xl:text-2md lg:text-md text-text group-hover/menu-item:lg:!text-primary text-2md group !block w-full py-3 font-normal tracking-[0.125rem] transition-colors duration-500 group-has-[.has-submenu:hover]/header:text-black lg:w-fit lg:whitespace-nowrap lg:rounded-lg lg:py-6 lg:text-center lg:font-medium lg:leading-8 xl:px-3 {menuItem
								.submenus.length > 0
								? '!pr-7'
								: ''} {isMenuActive(menuItem, pageSlug[locale] ?? '') ? '' : ''} {scrolled
								? 'lg:!text-text group-hover/menu-item:lg:!text-primary'
								: ''}"
							href={menuItem.path !== undefined ? `/${menuItem.path}` : undefined}
							target={menuItem.target ?? "_self"}
							on:click={() => {
								if (menuItem.path === undefined) {
									toggleSubmenu(menuItem);
								}
								dispatch("click", menuItem);
							}}
						>
							<span class="m-0 p-0">
								{nbspify(menuItem.name)}
								{#if menuItem.name.toLowerCase() === "e-shop"}
									<Icon
										class="mt-1 inline-block h-[1.4rem] align-text-top lg:ml-1 lg:h-[.8125rem]"
										icon={externalLinkIcon}
									/>
								{/if}
							</span>
							<span class="bg-primary hidden h-0.5 max-w-0 transition-all duration-500 group-hover:max-w-full lg:block"
							></span>
						</Link>

						{#if hasSubmenu}
							<Button
								variant="transparent"
								class="text-2lg flex h-min flex-1 items-center justify-end leading-none lg:hidden"
								on:click={() => {
									toggleSubmenu(menuItem);
								}}
							>
								{#if openSubmenus[menuItem.id]}
									-
								{:else}
									+
								{/if}
							</Button>
						{/if}
					</div>

					{#if hasSubmenu}
						<div
							class="bg-secondary left-0 right-0 top-[0.3125rem] hidden origin-top grid-rows-[0fr] py-4 shadow-black/25 transition-all duration-500 group-hover/menu-item:grid-rows-[1fr] group-hover/menu-item:opacity-100 lg:pointer-events-none lg:fixed lg:-z-10 lg:grid lg:pb-11 lg:pt-[7.75rem] lg:opacity-0 lg:shadow-2xl lg:group-hover/menu-item:pointer-events-auto lg:group-hover/menu-item:grid"
							class:max-lg:flex={openSubmenus[menuItem.id]}
							class:max-lg:scale-y-100={openSubmenus[menuItem.id]}
						>
							<div class="border-t-tertiary w-full overflow-hidden border-t-2 pt-4 lg:border-t-[1px] lg:px-3 lg:pt-8">
								<div class="container">
									<div
										class="flex w-full flex-col gap-5 lg:grid"
										style:grid-template-columns="repeat({menuItem.submenus.length}, 1fr)"
									>
										{#each menuItem.submenus as submenu}
											<div class="text-2md flex flex-col gap-5 lg:flex">
												{#if Array.isArray(submenu)}
													{#each submenu as menu, index}
														<Link
															noUnderline
															containerClass="w-full relative "
															class="hover:!text-primary !text-black {menu.showOnMobile === false
																? 'hidden lg:block'
																: ''}"
															href="/{menu.path}"
														>
															{#if menu.image}
																{#if index < submenu.length}
																	<div
																		class="bg-tertiary absolute left-1/2 top-0 h-[2px] w-10/12 -translate-x-1/2"
																		class:max-lg:hidden={index === 0}
																	></div>
																{/if}
															{/if}
															<div class="flex items-center gap-5" class:mt-4={menu.image}>
																{#if menu.image}
																	{#if typeof menu.image === "string"}
																		<StaticPicture image={menu.image} width={128} height={128} alt={menu.name} />
																	{:else}
																		<DynamicPicture
																			image={menu.image}
																			class="aspect-square h-16 object-contain"
																			width={86}
																			height={86}
																			alt={menu.image.title ?? menu.name}
																			bucket="storage"
																		/>
																	{/if}
																{/if}
																<span>{menu.name}</span>
															</div>
														</Link>
													{/each}
												{:else if submenu === SpecialMenuItem.Socials}
													<div class="hidden flex-wrap gap-28 pt-8 lg:flex">
														<Link
															href="https://www.facebook.com/groupbrano/?paipv=0&eav=AfYRcYA1-GsqsNVNIdevf2-kuPWkwW8L9ddB5GJmg1eHHY0bh6_Izl98Qu0wAeSR8Bs&_rdr"
															variant="transparent"
															icon={fb}
															iconClass="h-[1.75rem] text-[#AEAEB2]"
														/>
														<Link
															href="https://cz.linkedin.com/company/brano-a.s."
															variant="transparent"
															icon={linkedin}
															iconClass="h-[1.75rem] text-[#AEAEB2]"
														/>
														<Link
															href="https://www.instagram.com/brano.group"
															variant="transparent"
															icon={ig}
															iconClass="h-[1.75rem] text-[#AEAEB2]"
														/>
													</div>
												{/if}
											</div>
										{/each}
									</div>
								</div>
							</div>
						</div>
					{/if}
				</div>
			</div>
		{/each}
		<div class="menu-item mt-[4rem] lg:hidden">
			<div class="flex justify-between px-[1.6875rem]">
				<Link
					href="https://www.facebook.com/groupbrano/?paipv=0&eav=AfYRcYA1-GsqsNVNIdevf2-kuPWkwW8L9ddB5GJmg1eHHY0bh6_Izl98Qu0wAeSR8Bs&_rdr"
					variant="transparent"
					icon={fb}
					iconClass="h-[1.75rem] text-[#AEAEB2]"
				/>
				<Link
					href="https://cz.linkedin.com/company/brano-a.s."
					variant="transparent"
					icon={linkedin}
					iconClass="h-[1.75rem] text-[#AEAEB2]"
				/>
				<Link
					href="https://www.instagram.com/brano.group"
					variant="transparent"
					icon={ig}
					iconClass="h-[1.75rem] text-[#AEAEB2]"
				/>
			</div>
			<span class="mt-[4rem] flex flex-col">
				<a href="/" class="text-[1.75rem]">BRANO GROUP a.s.,</a>
				<a href="https://maps.app.goo.gl/LcbA6Q5u4FqycSB18" target="_blank" class="text-lg"
					>747 41 Hradec nad Moravicí, Czech Republic</a
				>
				<span class="mt-[2.5rem] flex flex-col">
					<a href="mailto:info@brano.cz" class="text-2md font-bold">info@brano.cz</a>
					<a href="tel:+420553632211" class="text-2md font-bold">+420 553 632 211</a>
				</span>
			</span>
			<div class="h-[5.625rem] w-full bg-transparent lg:h-0 lg:w-0"></div>
		</div>
	</div>
</div>
