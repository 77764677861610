<script lang="ts">
	import type { MenuItem } from "../../../../core/schema/MenuItem";
	import Menu from "./Menu.svelte";

	export let menu: MenuItem[];
	export let scrolled = false;
	export let menuTextColor = "text-text";
</script>

<nav class="hidden w-fit lg:block">
	<Menu {menuTextColor} {menu} {scrolled} />
</nav>
