<script lang="ts">
	import "../assets/css/main.css";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { getContext, onMount, tick } from "svelte";
	import { pageSlugContextKey } from "../contexts/pageSlugContextKey.js";
	import { titleContextKey } from "../contexts/titleContextKey.js";
	import { descriptionContextKey } from "../contexts/descriptionContextKey.js";
	import Footer from "./Footer.svelte";
	import MarketingFooter from "./MarketingFooter.svelte";
	import Header from "./Header.svelte";
	import HeadFavicons from "./HeadFavicons.svelte";
	import { menuContextKey } from "../contexts/menuContextKey.js";
	import GoogleAnalytics from "./GoogleAnalytics.svelte";
	import { breadcrumbsContextKey } from "../contexts/breadcrumbsContextKey.js";
	import { appName } from "../../../core/schema/appName.js";
	import { scrollLockStore } from "../stores/scrollLockStore.js";
	import PlusJakarta from "../../../core/assets/fonts/plus-jakarta-sans/PlusJakartaSans-Regular.ttf";
	import { createTitle } from "../utils/createTitle.js";
	import { keywordsContextKey } from "../contexts/keywordsContextKey.js";
	import { thumbnailImageContextKey } from "../contexts/thumbnailImageContextKey.js";
	import { localeLangMap } from "../../../core/schema/localeLangMap.js";
	import { tenants } from "../../../core/config/tenants.js";
	import type { GlobalTemplateProps } from "../GlobalTemplateProps.js";
	import { getTenant } from "../contexts/tenantContextKey.js";
	import MicrosoftClarity from "./MicrosoftClarity.svelte";
	import defaultImage from "../assets/images/og-image-brano.png";
	import { microsoftClaritySettingContextKey } from "../contexts/microsoftClaritySettingContextKey";
	import { gsap } from "gsap";
	import { ScrollSmoother } from "gsap/dist/ScrollSmoother";
	import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
	import { scrollSmootherStore } from "../stores/scrollSmootherStore.js";

	export let menuTextColor = "text-text";
	export let footerImage: string | undefined = undefined;
	export let footerHeadline: string | undefined = undefined;
	export let footerSubHeadline: string | undefined = undefined;
	export let footerButtonText: string | undefined = undefined;
	export let largeFooterImage = false;
	export let footerClass = "";
	export let footerImageClass = "";

	const { ROBOTS, WEB_URL } = getGlobalSettings();
	gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

	const breadcrumbs = getContext<GlobalTemplateProps["breadcrumbs"]>(breadcrumbsContextKey);
	const microsoftClaritySetting = getContext<GlobalTemplateProps["microsoftClaritySetting"]>(
		microsoftClaritySettingContextKey,
	);

	const pageSlug = getContext<GlobalTemplateProps["pageSlug"]>(pageSlugContextKey);
	const title = createTitle(getContext<GlobalTemplateProps["title"]>(titleContextKey));
	const description = getContext<GlobalTemplateProps["description"]>(descriptionContextKey); // TODO ?? defaultDescription
	const keywords = getContext<GlobalTemplateProps["keywords"]>(keywordsContextKey);
	const menu = getContext<GlobalTemplateProps["menu"]>(menuContextKey);
	const ogImage = getContext<string | undefined>(thumbnailImageContextKey) ?? defaultImage;

	// Smooth scroll breaks scrolling to anchor, handle manually.
	function handleHashChange(event: HashChangeEvent): void {
		if (!$scrollSmootherStore) {
			return;
		}

		const oldUrlObject = new URL(event.oldURL);
		const newUrlObject = new URL(event.newURL);
		if (newUrlObject.hash) {
			if (oldUrlObject.pathname !== newUrlObject.pathname) {
				return;
			}
			event.preventDefault();
			history.replaceState(null, "", newUrlObject);
			const element = document.querySelector(newUrlObject.hash);
			if (element) {
				$scrollSmootherStore.scrollTo(element, true, "top top");
			}
		}
	}

	function setupSmoothScroll(): void {
		if (window.matchMedia("(pointer: coarse)").matches) {
			// Set scroll behavior to smooth only after the page goes to the anchor on page load. It is buggy otherwise (probably because of critical styles)
			setTimeout(() => {
				document.documentElement.style.scrollBehavior = "smooth";
			}, 1);

			// Disable smooth scroll to allow swipe to reload on phones.
			return;
		}

		const { hash } = window.location;

		const smoother = ScrollSmoother.create({
			smooth: 1,
			effects: true,
			normalizeScroll: true,
		});
		scrollSmootherStore.set(smoother);

		for (const link of document.querySelectorAll("a")) {
			// eslint-disable-next-line @typescript-eslint/no-loop-func,no-loop-func
			link.addEventListener("click", (event) => {
				handleHashChange(
					Object.assign(event, {
						oldURL: window.location.toString(),
						newURL: link.href,
					}),
				);
			});
		}

		if (hash) {
			const element = document.querySelector(hash);
			if (element) {
				smoother.scrollTo(element, true, "top top");
			}
		}
	}

	onMount(() => {
		// @ts-expect-error fslightbox does not provide types :)
		void import("../assets/js/fslightbox-pro-3.4.1/fslightbox.js");

		if (window.matchMedia("(pointer: coarse)").matches) {
			window.scrollTo = () => {
				// ScrollTrigger refresh makes the page go to the top on Safari :)
				// https://github.com/nuxt/nuxt/issues/15010
			};
		}
		screen.orientation.addEventListener("change", () => {
			void tick().then(() => {
				ScrollTrigger.refresh();
			});
		});

		setupSmoothScroll();
	});

	const { locale } = getTenant();
	const canonical = `${WEB_URL}/${pageSlug[locale] ?? ""}`;
	$: {
		document.documentElement.style.overflow = $scrollLockStore ? "hidden" : "auto";
	}
</script>

<svelte:window on:hashchange={handleHashChange} />

<svelte:head>
	<!-- Preloads of fonts used the top pages -->
	<!-- TODO Revise... -->
	{#each [PlusJakarta] as font}
		<link rel="preload" as="font" type="font/ttf" href={font} crossorigin="anonymous" />
	{/each}

	<!-- Site name -->
	<meta property="og:site_name" content={appName} />
	<meta name="twitter:site" content={appName} />
	<meta name="application-name" content={appName} />
	<meta name="apple-mobile-web-app-title" content={appName} />

	<!-- Dynamic Open graph tags (Meta) -->
	<meta property="og:url" content={canonical} />
	{#if ogImage}
		<meta property="og:image" content={ogImage} />
	{/if}

	<!-- Dynamic Twitter Meta tags -->
	<meta name="twitter:card" content="summary_large_image" />
	<meta property="twitter:domain" content={WEB_URL} />
	<meta property="twitter:url" content={canonical} />
	{#if ogImage}
		<meta name="twitter:image" content={ogImage} />
	{/if}

	<!-- Title, description and keywords -->
	<title>{title}</title>
	<meta property="og:title" content={title} />
	<meta property="og:title" content={title} />
	<meta name="twitter:title" content={title} />
	<meta name="description" content={description} />
	<meta name="og:description" content={description} />
	<meta name="twitter:description" content={description} />
	{#if keywords}
		<meta name="keywords" content={keywords} />
	{/if}

	<!-- Canonical and hreflang -->
	<link rel="canonical" href={canonical} />
	{#each Object.values(tenants) as tenant}
		{#if tenant.locale in pageSlug}
			<link rel="alternate" hreflang={localeLangMap[tenant.locale]} href="{WEB_URL}/{pageSlug[tenant.locale]}" />
		{/if}
	{/each}

	<!-- Robots -->
	<meta name="robots" content={`${ROBOTS}, ${ROBOTS === "noindex" ? "no" : ""}follow`} />
</svelte:head>

<HeadFavicons />

{#if microsoftClaritySetting && microsoftClaritySetting.enabled}
	<MicrosoftClarity />
{/if}

<GoogleAnalytics />

<Header class="2xl:mx-auto 2xl:w-full" {menuTextColor} {menu} />

<div id="smooth-wrapper">
	<div id="smooth-content">
		<main class="grow 2xl:mx-auto 2xl:w-full">
			<slot />
		</main>

		<Footer
			image={footerImage}
			headline={footerHeadline}
			subHeadline={footerSubHeadline}
			buttonText={footerButtonText}
			largeImage={largeFooterImage}
			class={footerClass}
			imageClass={footerImageClass}
		/>
	</div>
</div>

<MarketingFooter />
